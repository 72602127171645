import { Component, Prop, State, Host, h } from '@stencil/core';
import { getSubscriberID } from '../../utils/utils';

import type {
  CityState,
  GridResponseProperties,
  PointResponse,
  PeriodForecast,
} from './weatherTypes';

@Component({
  tag: 'my-weather',
  styleUrl: 'my-weather.css',
  shadow: false,
  scoped: false,
})
export class MyWeather {
  @State()
  curLocation: CityState = null;

  @State()
  forecast: GridResponseProperties;

  /**
   * Where to save the subscribers's location
   */
  @Prop() locationEndpoint: string;

  /**
   * Where to get weather data for the subscriber
   */
  @Prop() forecastEndpoint: string;

  saveLocation(
    gridId: string,
    gridX: string,
    gridY: string,
    cityState: CityState,
    timeZone: string,
  ): void {
    const body = {
      gridId,
      gridX,
      gridY,
      city: cityState.city,
      state: cityState.state,
      timeZone,
    };

    fetch(`${this.locationEndpoint}/${getSubscriberID()}`, {
      method: 'PUT',
      body: JSON.stringify(body),
    });
  }

  componentDidLoad() {
    const fetchGridData = (lat: number, longitude: number) => {
      fetch(`https://api.weather.gov/points/${lat},${longitude}`)
        .then((res: Response) => {
          console.log('LAT', lat);
          console.log('LONG', longitude);
          if (res.ok) {
            return res.json();
          } else {
            console.error(res.body);
          }
        })
        .then((body: PointResponse) => {
          console.log(body);
          const { gridId, gridX, gridY, relativeLocation, timeZone } =
            body.properties;

          this.curLocation = relativeLocation.properties;
          this.saveLocation(
            gridId,
            gridX,
            gridY,
            relativeLocation.properties,
            timeZone,
          );
        });
    };

    navigator.geolocation.getCurrentPosition(position => {
      fetchGridData(position.coords.latitude, position.coords.longitude);
    });
  }

  render() {
    const { city, state } = this.curLocation || {};
    const { forecastEndpoint, forecast } = this;

    if (this.curLocation && !forecast) {
      fetch(`${forecastEndpoint}/${getSubscriberID()}/hourly`)
        .then((res: Response) => {
          if (res.ok) return res.json();
        })
        .then((body: GridResponseProperties) => {
          console.log(body);
          this.forecast = body;
        });
    }

    // TODO: Improve this and add error handling
    const forecastToCondition = (shortForecast: string): string => {
      console.log('SHORT FORECAST', shortForecast);
      return shortForecast
        .toLowerCase()
        .match(/(cloudy|rain|sunny|clear|thunderstorms)/)[0];
    };

    const createDayCode = (periods: PeriodForecast[]) => {
      let prevCondition = periods[0].forecast;
      let lastEnd = -1;
      let hour = 1;
      const segments = periods.reduce((acc, cur) => {
        // console.log('CUR', cur);
        if (cur.forecast !== prevCondition || hour === 24) {
          const startHour = new Date(cur.start).getHours();
          acc.push(
            `${lastEnd + 1}-${hour === 24 ? startHour + 1 : startHour}:${forecastToCondition(prevCondition)}`,
          );

          lastEnd = startHour - 1;
          prevCondition = cur.forecast;
        }
        hour++;

        return acc;
      }, []);

      return segments.join('|');
    };

    const getTomorrow = () => {
      return Object.keys(forecast.days)[1];
    };

    return (
      <Host>
        {!this.curLocation && <div>Waiting to get weather data</div>}

        {this.curLocation && forecast && (
          <div>
            Here is a short summary of the weather in&nbsp;
            <span class="font-bold">
              {city}, {state}
            </span>
            <br />
            <ol>
              {Object.entries(forecast.days).map(([day, dailyForecast]) => {
                console.log('DAY', day);
                const dayCode = createDayCode(dailyForecast.periods);
                console.log(dayCode);
                return (
                  <li key={day}>
                    {day}
                    <day-bar dayCode={dayCode}></day-bar>
                  </li>
                );
              })}
            </ol>
            <span>Hourly temperatures for {getTomorrow()}</span>
            <hourly-view
              periods={forecast.days[getTomorrow()].periods}
            ></hourly-view>
          </div>
        )}
      </Host>
    );
  }
}
