/* @import '~chartist/dist/index.css'; */

:host {
  display: block;
}

#chart {
  height: 80vh;
}

line {
  /* @apply stroke-white; */
  stroke: white;
  stroke-width: 2px;
}

rect {
  fill: white;
}
